import React from 'react'
import { Link, StaticQuery, graphql } from "gatsby"
import { getPostsFromQuery } from '../utils/post'
import PostUtils from '../utils/post_utils'

const SidebarPostsComponent = ({ title, posts }) => {
  return (
    <div className="mb-7">
      <div className="mb-3">
        <h3>{title}</h3>
      </div>
      
      {posts.map((post, i) => (
        <article className="mb-2" key={i}>
          <Link className="card card-frame p-3" to={post.slug} itemProp="url">
            <div className="media align-items-center">
              <div className="media-body mr-2">
                <h4 className="h6 mb-0">{post.title}</h4>
              </div>
            </div>
          </Link>
        </article>
      ))}
    </div>
  )
}

const postsSidebar = (props) => (
  <StaticQuery
    query={graphql`
      query SidebarPosts {    
        posts: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 1000
        ) {
          edges {
            node {
              fields {
                slug
              }
              excerpt(pruneLength: 80)
              frontmatter {
                title
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 350) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                tags
                categories
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { title, terms, termType, maxPosts } = props;
      const posts = getPostsFromQuery(data.posts);
      const finalPosts = new PostUtils(posts, "/", terms, termType, maxPosts)
        .getPosts()

      return (
        <SidebarPostsComponent title={title} posts={finalPosts} />
      )
      
    }}
  />
)

export default postsSidebar;