import React from "react"
import kebabCase from "lodash/kebabCase"
import { StaticQuery, Link, graphql } from "gatsby"
const _ = require("lodash");

const tagsSidebar = (props) => (
  <StaticQuery
    query={graphql`
      query {
        tags: allMarkdownRemark(limit: 2000) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
      }
    `}
    render={data => {
      const { maxTags } = props;
      let tags = data.tags.group.slice(0, maxTags);
      return (
        <div className="mb-7">
          <div className="mb-3">
            <h3>Tags</h3>
          </div>

          {tags.map((tag, i) => (
            <Link key={i} className="btn btn-xs btn-soft-secondary mb-1 mr-2" 
              to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                {_.startCase(_.toLower(tag.fieldValue))}
            </Link>
          ))}
        </div>
      )
    }}
  />
)

export default tagsSidebar;