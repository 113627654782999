import React from "react"
import { Link, graphql } from "gatsby"

import SingleLayout from '../components/layouts/SingleLayout_10'
import Seo from "../components/seo"
import SidebarComponent from '../components/content_utils/posts_sidebar'
import Pagination from '../components/utils/pagination'
import TagsComponent from '../components/content_utils/tags_sidebar'
import RandomPostsSidebar from '../components/content_utils/random_posts_sidebar'
import Img from "gatsby-image"
import '../styles/app.css'
const _ = require("lodash");

const BlogIndex = ({ data, pageContext, location }) => {
  // const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const hero = {
    title:"Explore the Programming Blog", 
    image: "/images/coding.jpg"
  }
  return (
    <>
    <SingleLayout hero={hero}>
      <Seo 
        title="All posts" 
        description="Browse through all interesting posts. Explore about SEO and Coding Interview Questions"
        image="/images/coding.jpg"
        />
      <div className="row justify-content-lg-between">
        <div className="col-lg-8">
          {posts.map(({ node }, i) => {
            const featuredImgFluid = node.frontmatter.featuredImage.childImageSharp.fluid;
            const title = node.frontmatter.title || node.fields.slug;
            let category = node.frontmatter.categories;
            if (category) {
              category = category[0];
            }
            
            return (
              <article key={i} className="row mb-7">
                <div className="col-md-5">
                  <Link to={node.fields.slug} itemProp="url">
                    <Img fluid={featuredImgFluid} alt={title} title={title} />
                  </Link>
                </div>
                <div className="col-md-7">
                  <div className="card-body d-flex flex-column h-100 px-0 pt-2">
                    <span className="d-block mb-2">
                      <Link to={`/${category}`} itemProp="url" className="font-weight-bold">
                        {_.startCase(_.toLower(node.frontmatter.categories))}
                      </Link>
                    </span>
                    <h3>
                      <Link to={node.fields.slug} itemProp="url" className="text-inherit">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h3>
                    <p key={i}>{node.excerpt}</p>
                  </div>
                </div>
              </article>
            )
          })}

          <Pagination 
            currentPage={pageContext.currentPage} 
            numPages={pageContext.numPages} 
            baseSlug="posts" />
        </div>
        <div className="col-lg-3">
          <SidebarComponent 
                title="Coding Interview Questions"
                maxPosts="6"
                terms="coding-interview"
                termType="category"/>

          <div id="stickyBlockStartPoint"></div>
          <div className="js-sticky-block"
                data-hs-sticky-block-options='{
                  "parentSelector": "#stickyBlockStartPoint",
                  "breakpoint": "lg",
                  "startPoint": "#stickyBlockStartPoint",
                  "endPoint": "#stickyBlockEndPoint",
                  "stickyOffsetTop": 40,
                  "stickyOffsetBottom": 20
                }'>

            <RandomPostsSidebar title="Popular Posts" maxPosts="4" />
            <TagsComponent title="Explore Tags" maxTags="7" />
          </div>
        </div>

      </div>
    </SingleLayout>
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 50)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            categories
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 450) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
