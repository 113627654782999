import React from 'react'
import { Link } from "gatsby"

export default function Pagination({currentPage, numPages, baseSlug}) {
  let prevClass = 'page-item';
  if (currentPage === 1) {
    prevClass = 'page-item disabled';
  }

  let nextClass = 'page-item';
  if (currentPage === numPages) {
    nextClass = 'page-item disabled';
  }
  
  if (baseSlug.startsWith('/')) {
    baseSlug = baseSlug.substring(1)
  }
  if (baseSlug.endsWith('/')) {
    baseSlug = baseSlug.substring(0, baseSlug.length-1)
  }

  return (
    <nav>
        <ul className="pagination justify-content-center">
          <li className={prevClass}>
            <a className="page-link" 
              href={currentPage === 2 ? `/${baseSlug}/` : `/${baseSlug}/${currentPage-1}`}>
                Previous
            </a>
          </li>
          {
            Array.from(Array(numPages), (e, i) => {
                return <li className="page-item" key={i}>
                  <Link className="page-link" 
                    to={i===0 ? `/${baseSlug}/` : `/${baseSlug}/${i+1}`} 
                    itemProp="url">
                      {i+1}
                  </Link>
                </li>
            })
          }

          <li className={nextClass}>
            <a className="page-link" href={`/${baseSlug}/${currentPage+1}`}>
              Next
            </a>
          </li>
        </ul>
      </nav>
  )
}
