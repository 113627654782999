import React from 'react'
import { Link, StaticQuery, graphql } from "gatsby"
import { getPostsFromQuery, shuffleArray} from '../utils/post'
// import SingleCard from '../cards/card'
import Img from "gatsby-image"

const RandomPostsSidebarComponent = ({ title, posts }) => {
  return (
    <div className="mb-7">
      <div className="mb-3">
        <h3>{title}</h3>
      </div>
      {posts.map((post, i) => (
      <article key={i} className="mb-5">
        <div className="media align-items-center text-inherit">
          <div className="avatar avatar-lg mr-3">
            <Link to={post.slug} itemProp="url">
              <Img 
                className="avatar-img"
                fluid={post.featuredImage.childImageSharp.fluid} 
                alt={post.title} 
                title={post.title} />
            </Link>
          </div>
          <div className="media-body">
            <h4 className="h6 mb-0">
              <Link className="text-inherit" to={post.slug} itemProp="url">
                {post.title}
              </Link>
            </h4>
          </div>
        </div>
      </article>
      ))}
    </div>
  )
}

const randomPosts = (props) => (
  <StaticQuery
    query={graphql`
      query LatestPostsSidebar {    
        posts: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 1000
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 350) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                tags
                categories
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { title, currentPostSlug, maxPosts } = props;
      let posts = getPostsFromQuery(data.posts);
      if (currentPostSlug) {
        posts = posts.filter((aArticle) => aArticle.slug !== currentPostSlug);
      }
      let finalPosts = posts.splice(0, maxPosts);
      shuffleArray(finalPosts);
      return (
        <RandomPostsSidebarComponent title={title} posts={finalPosts} />
      )
      
    }}
  />
)

export default randomPosts;